import { readClubs } from '../firebase/firebaseRepository';
import { useState, useEffect } from 'react';
import ClubCard from './ClubCard';
import Fuse from 'fuse.js';
import Search from './Search';

// returns a list of unique values of that property
function getUniqueValues(allData, property) {
	return [...new Set(allData.map((doc) => doc[property]))].map((value) =>
		value === '' ? 'No Time Specified' : value,
	);
}

export default function ClubCollection() {
	const [loading, setLoading] = useState(true);
	const [allData, setAllData] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [dateFilters, setDateFilters] = useState([]);
	const [timeFilters, setTimeFilters] = useState([]);
	const [tagFilters, setTagFilters] = useState([]);
	const [searchFields, setSearchFields] = useState([
		'name',
		'description',
		'day',
		'time',
		'tags',
	]);

	const options = {
		includeScore: true,
		includeMatches: true,
		threshold: 0.2,
		ignoreLocation: true,
		keys: searchFields,
	};

	const fuse = new Fuse(allData == null ? [] : allData, options);

	const RELOAD_LS_TIME = 1000 * 60 * 60 * 1; // reload local storage every x milliseconds (1 hour)

	// Call once on component mount: load allData from firebase or from local storage
	useEffect(() => {
		setLoading(true);

		// Read values from local storage
		let lastLoaded = Date.parse(localStorage.getItem('last_loaded'));
		let clubs = JSON.parse(localStorage.getItem('clubs'));

		// load clubs from firebase if specified time has passed / clubs is empty
		if (
			isNaN(lastLoaded) ||
			new Date() - lastLoaded >= RELOAD_LS_TIME ||
			clubs == null ||
			clubs.length === 0
		) {
			new Promise(readClubs).then((clubs) => {
				setAllData(clubs);
				setLoading(false);
			});
		} else {
			setAllData(clubs);
			setLoading(false);
			console.log('Loaded clubs from local storage.');
		}
	}, []);

	// When allData is set, give collection to search and display
	useEffect(() => {
		if (allData != null) {
			// localStorage.setItem("clubs", JSON.stringify(allData));
			fuse.setCollection(allData);
			setSearchResults(allData);
		}
	}, [allData]);

	// When the search query changes, update the data
	useEffect(() => {
		let query = {
			$and: [],
		};

		// normal fuzzy search
		if (searchQuery.length !== 0) {
			query.$and.push({
				$or: searchFields.map((field) => ({
					[field]: searchQuery,
				})),
			});
		}

		// date filters
		if (dateFilters.length > 0) {
			query.$and.push({
				$or: dateFilters.map((filter) => ({ day: filter })),
			});
		}

		// time filters
		if (timeFilters.length > 0) {
			query.$and.push({
				$or: timeFilters.map((filter) => ({ time: filter })),
			});
		}

		// tag filters
		if (tagFilters.length > 0) {
			query.$and.push({
				$or: tagFilters.map((filter) => ({ tags: filter })),
			});
		}

		if (query.$and.length === 0) {
			setSearchResults(allData);
			return;
		}

		let results = fuse.search(query);
		const items = results.map((result) => result.item);

		setSearchResults(items);
	}, [searchQuery, searchFields, dateFilters, timeFilters, tagFilters]);

	const handleSearch = (event) => {
		const { value } = event.target;

		setSearchQuery(value);
	};

	return (
		<div className="p-5">
			<div className="w-100 mb-6 mt-8 lg:mb-10 lg:mt-12">
				<div className="text-4xl font-display font-bold text-center lg:text-left ">
					LAHS Club List
				</div>
				<div className="italic font-body mt-1 text-center lg:text-left">
					A project by the Data Club.
				</div>
			</div>
			{loading && (
				<div role="status">
					<svg
						aria-hidden="true"
						className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-gray-600"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="currentColor"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentFill"
						/>
					</svg>
					<span className="sr-only">Loading...</span>
				</div>
			)}
			{allData.length > 0 && (
				<div className="flex-col flex">
					<Search
						onChange={handleSearch}
						setDateFilters={setDateFilters}
						setTimeFilters={setTimeFilters}
						setTagFilters={setTagFilters}
						dateFilters={dateFilters}
						timeFilters={timeFilters}
						tagFilters={tagFilters}
						dateValues={[
							'Monday',
							'Wednesday',
							'Thursday',
							'Friday',
						]}
						timeValues={getUniqueValues(allData, 'time')}
						tagValues={[
							'Helping Others',
							'Awareness',
							'Outreach',
							'Teamwork',
							'Community',
							'Health/Disabilities',
							'STEM',
							'Diversity',
							'Education',
							'Competition',
							'Design',
							'Art',
							'Creative',
							'Leisure',
							'Music',
							'Cultural',
							'Public Speaking',
							'Leadership',
							'Food',
							'Computer Science',
							'Sports',
						]}
					></Search>
					<div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-4">
						{searchResults.map((club) => (
							<ClubCard {...club} key={club.id}></ClubCard>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
